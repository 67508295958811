h1,
.h1 {
    font-family: 'Epilogue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 2.5em;
    line-height: 1.1;
}

h2,
.h2 {
    font-family: 'Epilogue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 2em;
    line-height: normal;
}

h3,
.h3 {
    font-family: 'Epilogue', sans-serif;
    font-style: normal;
    font-size: 1.75em;
    line-height: normal;
}

h4,
.h4 {
    font-family: 'Epilogue', sans-serif;
    font-style: normal;
    font-size: 1.5em;
    line-height: normal;
}

h5,
.h5 {
    font-family: 'Epilogue', sans-serif;
    font-style: normal;
    font-size: 1.25em;
    line-height: normal;
}

h6,
.h6 {
    font-family: 'Epilogue', sans-serif;
    font-style: normal;
    font-size: 1em;
    line-height: normal;
}

p,
.p {
    font-style: normal;
    font-size: 1em;
    line-height: normal;
}

small,
.small {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-size: 0.75em;
    line-height: normal;
}

.medium-text {
    font-size: 0.75em;
}

.large-text {
    font-size: 1em;
}

.huge-text {
    font-size: 4em;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.main-title {
    color: #050505;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    @media (min-width: 1600px) {
        font-size: 40px;
    }
}

.main-title-wrapper {
    height: fit-content;
    margin-bottom: 32px;
}

.centered-text-compact {
    font-family: 'Epilogue', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 40px;
    font-size: 32px;
    @media (min-width: 1600px) {
        font-size: 40px;
    }
}

.font-bungee {
    font-family: 'Epilogue', sans-serif;
}

.font-sans {
    font-family: 'Open Sans', sans-serif;
}

.weight-100 {
    font-weight: 100;
}

.weight-200 {
    font-weight: 200;
}

.weight-300 {
    font-weight: 300;
}

.weight-400 {
    font-weight: 400;
}

.weight-500 {
    font-weight: 500;
}

.weight-600 {
    font-weight: 600;
}

.weight-700 {
    font-weight: 700;
}

.weight-800 {
    font-weight: 800;
}

.weight-900 {
    font-weight: 900;
}

.size-10 {
    font-size: 10px;
}

.size-12 {
    font-size: 12px;
}

.size-14 {
    font-size: 14px;
}

.size-16 {
    font-size: 16px;
}

.size-18 {
    font-size: 18px;
}

.size-20 {
    font-size: 20px;
}

.size-22 {
    font-size: 22px;
}

.size-24 {
    font-size: 24px;
}

.size-26 {
    font-size: 26px;
}

.size-28 {
    font-size: 28px;
}

.size-30 {
    font-size: 30px;
}

.size-32 {
    font-size: 32px;
}

.size-33 {
    font-size: 33px;
}

.size-34 {
    font-size: 34px;
}

.size-35 {
    font-size: 35px;
}

.size-36 {
    font-size: 36px;
}

.size-38 {
    font-size: 38px;
}

.size-40 {
    font-size: 40px;
}

.size-huge {
    font-size: 4em;
}

.text-black {
    color: black;
}

.no-wrap {
    white-space: nowrap;
}

.bold {
    font-weight: 700;
}

.text-center {
    text-align: center;
}