.main-bottom {
    text-align: left;
    align-items: center;
    display: flex;
    width: 80%;
    height: 10%;
}

.main-btn-left {
    flex-direction: row;
}

.main-btn-right {
    flex-direction: row-reverse;
}

.main-btn-dual {
    flex-direction: row;
    justify-content: space-between;
}
