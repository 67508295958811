*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

body {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.6;
    color: #000;
    background: #fff;
    overflow-x: hidden;
    /*height: calc(var(--vh, 1vh) * 100);*/
}

h1,
h2,
h3 {
    font-family: 'Epilogue', sans-serif;
    font-weight: 400;
}
