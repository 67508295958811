.socket-container {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    .socket-answer-reveal {
        margin-top: 40px;
    }
    .socket-input {
        resize: none;
        align-self: center;
        width: 100%;
        min-height: 131px;
        flex-shrink: 0;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        color: rgba(0, 0, 0, 0.39);
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        &.inActive {
            background-color: rgb(148 163 184);
        }
        border: 2px solid rgb(22, 145, 190);
    }
    .socket-input-length {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 0px 10px;
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.39);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: right;
    }
    .socket-confirm-button {
        &.closed {
            width: 50%;
            transform: translateX(50%);
            margin: 35px 0px;
        }
        &.open {
            width: 100%;
            margin-top: 50px;
        }
        &.sent {
            color: white;
            border-color: black;
            background-color: rgb(22, 145, 190);
            cursor: default;
        }
        &.mt-100 {
            margin-top: 100px;
        }
    }
    .button-group-gv {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        .socket-button {
            border: 0;
            width: 140px;
            height: 60px;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border: 1px solid #263f47;
        }
    }
}
.socket-button-group {
    display: flex;
    gap: 8px;
    &.gap-16 {
        gap: 16px;
    }
    justify-content: center;
}

.socket-buttons-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.socket-button-group .button {
    border: 1px solid #263f47;
    width: 80px;
    height: 80px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 2px 0px 0px #263f47;
    font-family: 'Open Sans', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.socket-button-back {
    display: inline-flex;
    padding: 8px 25px;
    justify-content: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #000;
    color: rgba(0, 0, 0, 0.67);
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.socket-custom-button {
    padding: 16px 16px;
    align-items: flex-start;
    border-radius: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    outline: none;
    border: none;
    flex-shrink: 0;
    color: #393939;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.socket-start-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.socket-start-input {
    border: 1px solid #263f47;
    text-align: center;
    width: 50%;
    height: 3rem;
    font-size: 2rem;
}
