.central-wrapper {
    position: relative;
    text-align: left;
    display: flex;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 0 calc(var(--layout-gap) * 3);
    h2.h1 {
        color: #345059;
        font-size: 2.5rem;
    }
}

.start-page {
    width: 100%;
    .content-wrapper {
        display: flex;
        flex-direction: row;
        z-index: 1;
        gap: 40px;
        align-items: center;
        position: relative;
        justify-content: center;
        &.bottom {
            align-items: flex-end;
        }

        .intro {
            width: 40%;
            font-size: 2em;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            background: #fffaf2;
            scale: 1.4;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                mix-blend-mode: darken;
            }
            svg {
                width: 75%;
            }
        }
        .button-wrapper {
            width: 40%;
            display: flex;
            flex-direction: column;
            gap: 48px;
            align-items: center;
            img {
                width: 90%;
                height: 100%;
                mix-blend-mode: multiply;
                object-fit: contain;
            }
            .button {
                font-size: 1.75em;
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
                &.primary {
                    font-size: 1.4em;
                    text-wrap:balance;
                }
                &.secondary {
                    font-weight: 400;
                    background: #e3f5fb;
                    border: 1px solid #000;
                    border-bottom-width: 6px;
                }
            }
        }
    }
}

.socket-font-small {
    font-size: 0.887778em;
}
.socket-logo-wrapper {
    top: 0;
    background-color: #345059;
    color: #eaf4f7;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    border-bottom-style: solid;
    border-bottom-width: 4px;
}

.socket-logo-wrapper picture img {
    max-width: 160px;
    width: 100%;
    color: red;
}

.logo-wrapper {
    z-index: 999;
    position: absolute;
    bottom: var(--layout-gap);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: calc(100% - calc(var(--layout-gap) * 6));
    justify-content: space-between;
    //max-width: 90%;
    &.top {
        top: var(--layout-gap);
        bottom: auto;
        justify-content: center;
        svg {
            width: 300px;
        }
    }
    .logo {
        font-size: 18px;
        flex-direction: row;
        gap: 24px;
        display: flex;
        align-items: center;
        svg {
            height: 96px;
        }
    }
}

.never-vanilla-css-again {
    transform: translateY(-10rem);
}

.how-to-name-classnames {
    transform: translateY(5rem);
}

.width-intro {
    max-width: 800px;
}

.width-tips {
    max-width: 1000px;
}
