.guess-answer-reveal-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    font-size: 1.5rem;
    .number-wrapper {
        background: white;
        padding: 24px;
        border-radius: 0px 40px 40px 40px;
        border: 1px solid rgb(51, 77, 86);
        font-size: 40px;
        width: 200px;
        text-align: right;
    }
}
