.local-lobby-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 0;
    .image-wrapper {
        background: #fffaf2;
        position: absolute;
        top: 60%;
        width: 100%;
        transform: translateY(-57%);
        z-index: -1;
        img {
            width: 100%;
            mix-blend-mode: darken;
            object-fit: contain;

        }
    }
    h1 {
        margin-bottom: 32px;
    }
}

.socket-lobby-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 16px 0;
    max-height: 90%;
}
