.selectionWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 32px;
    max-height: 310px;
    min-height: 310px;
    align-items: flex-end;
}

.selectedCard {
    position: relative;
    border-radius: 30px;
}

.devices,
.cardboard {
    position: relative;
    border-radius: 30px;
    flex-direction: column;
    max-height: 280px;
    transition: max-height 0.2s ease-in-out;
    text-align: left;
    display: flex;
    min-width: 300px;
    width: 100%;
    max-width: 320px;
    height: 100%;
    scroll-behavior: smooth;
    overflow-wrap: break-word;
    justify-content: flex-end;
    &:hover {
        cursor: pointer;
    }
    &.selectedCard {
        max-height: 310px;
    }
}

.unselectedCard {
    position: relative;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
}

.svgWrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: scale 0.2s ease-in-out;
    transform-origin: bottom;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
        transform: translateY(-33px);
        height: 90%;
        object-fit: contain;
        transition: height .2s;
        z-index: -1;
    }
}

.devices {
    background: #ecfbd9;
    border: 1px solid #4bc695;
    border-bottom: 6px solid #4bc695;
    color: #273c44;
    .flex.w-full {
        background: #ecfbd9;
        z-index: 1;
        border-radius: 0 0 30px 30px;
    }
}
.cardboard {
    background: #e9fbfb;
    border: 1px solid #1691be;
    border-bottom: 6px solid #1691be;
    color: #345059;
    .flex.w-full {
        background: #e9fbfb;
        z-index: 1;
        border-radius: 0 0 30px 30px;
    }
}

.select-wrapper {
    padding: 16px;
    border-top: 2px solid #000;
    gap: 16px;
    &.digital {
        border-color: #1691be;
    }
    &.local {
        border-color: #4bc695;
    };
}

.cardText {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    bottom: 0;
    &.selected {
        padding-top: 20px;
    }
}

.rinput {
    border-top: 2px solid;
    &.digital {
        border-color: #4bc695;
    }
    &.local {
        border-color: #1691be;
    }
}

._rinput {
    border: 1px solid;
    border-radius: 9999px;
    padding: 8px 16px;
    &.selected {
        border-color: #ff7c03;
        background-color: white;
    }
    &.aselected {
        border-color: #000;
    }
}

.rounded-full {
    border-radius: 9999px;
}

.devices {
    .instruction {
        z-index: 2;
        pointer-events: none;
        position: absolute;
        left: -180px;
        bottom: -70px;
        rotate: 10deg;
        @media (min-width: 1600px) {
            rotate: inherit;
            left: -160px;
            bottom: -100px;
        }
    }
}

.cardboard {
    .instruction {
        z-index: 2;
        pointer-events: none;
        position: absolute;
        right: -70px;
        rotate: -10deg;
        bottom: -70px;
        @media (min-width: 1600px) {
            rotate: inherit;
            right: -100px;
            bottom: -100px;
        }
    }
}

.grow {
    flex-grow: 1;
}

.grow-0 {
    flex-grow: 0;
}
