.header-logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
        height: 100%;
        width: 100%;
        max-height: 40px;
    }
}

.quiz-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    .module {
        font-family: 'Epilogue', sans-serif;
        font-size: 0.75em;
        font-weight: 600;
    }
    .question-counter {
        font-family: 'Epilogue', sans-serif;
        font-size: 1.2em;
        transform: translate(-20px, 4px);
        .counter-text {
            padding: 8px 16px;
            background: white;
            border-radius: 8px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            line-height: normal;
            position: absolute;
            right: -96px;
            bottom: 4px;
            width: 90px;
            text-align: center;
            span {
                line-height: normal;
            }
            span:first-child {
                font-weight: 900;
            }
            span:last-child {
                font-weight: 400;
            }
        }
        .counter-type {
            img {
                height: 56px;
            }
        }
    }
}

.quizmaster-wrapper {
    font-size: 1.2em;
    font-family: 'Epilogue', sans-serif;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.reward-display-money {
    font-size: 0.8em;
    font-weight: 700;
}

.trial-className {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 300px;
    height: 300px;
    transform: rotateZ(315deg) translate(0px, -250px);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 1.6rem;
    color: rgb(255, 255, 255);
    background: #ff7c03;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
}
