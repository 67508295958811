//WHY ARE WE USING SCSS EVEN
.square-button {
    font-family: 'Epilogue', sans-serif;
    font-size: 32px;
    display: flex;
    width: 80px;
    height: 56px;
    transition: .2s background-color;
    @media (min-width: 640px) {
        width: 96px;
        height: 80px;
    }
    @media (min-width: 768px) {
        width: 136px;
        height: 120px;
    }
    @media (min-width: 1024px) {
        width: 160px;
        height: 140px;
    }

    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid #334d56;
    box-shadow: 0px 4px 0px 0px #334d56;
    &.unselected {
        background-color: #fdfbfe;
    }
    &.selected {
        color: #ffffff;
        background-color: #1691be;
    }
}

.square-row {
    gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}
