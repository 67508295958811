.team-status-wrapper-absolute {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

.team-status-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    min-width: 50px;
    border-radius: 20px;
    gap: 10px;
}

.team-status-box {
    width: 60px;
    border: 1px solid #334d56;
    height: 60px;
    border-radius: 20px 0px 0px 20px;
    background: rgba(113, 113, 113, 0.31);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(51, 77, 86) 0px 2px 0px 0px;
    &.opacity-50 {
        opacity: 0.4;
        box-shadow: rgb(51, 77, 86) 0px 0px 0px 0px;
    }
}

.team-status-number {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #334d56;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
    color: #fff;
    font-family: 'Epilogue', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.team-code-error{
    color:#ff2814;
    text-align: center;
    width:100vw;
    display: inline-block;
    z-index:0;
    font-size: 18px;
    font-weight:700;
}