.quiz-text-wrapper {
    width: 100%;
    display: flex;
    gap: 16px;
    padding: 16px;
    border-top: 1px solid var(--card-color);
}

.quiz-card-title {
    text-align: left;
    position: relative;
    bottom: 0;
    width: 100%;
    display: inline-block;
    color: #345059;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    @media (max-width: 768px) {
        font-size: 18px;
    }
}

.list-card:nth-child(3n + 1) {
    .quiz-card-information {
        --card-color: #f09e22;
        border-left: 1px solid var(--card-color);
        border-right: 1px solid var(--card-color);
        border-top: 1px solid var(--card-color);
    }
}

.list-card:nth-child(3n + 2) {
    .quiz-card-information {
        --card-color: #1691be;
        border-left: 1px solid var(--card-color);
        border-right: 1px solid var(--card-color);
        border-top: 1px solid var(--card-color);
    }
}

.list-card:nth-child(3n + 3) {
    .quiz-card-information {
        --card-color: #c64b51;
        border-left: 1px solid var(--card-color);
        border-right: 1px solid var(--card-color);
        border-top: 1px solid var(--card-color);
    }
}

// .list-card:nth-child(4) {
//     .quiz-card-information {
//         --card-color: #0a804d;
//         border-left: 1px solid var(--card-color);
//         border-right: 1px solid var(--card-color);
//         border-top: 1px solid var(--card-color);
//     }
// }

// .list-card:nth-child(5) {
//     .quiz-card-information {
//         --card-color: #f09e22;
//         border-left: 1px solid var(--card-color);
//         border-right: 1px solid var(--card-color);
//         border-top: 1px solid var(--card-color);
//     }
// }

.quiz-card-text {
    display: inline-block;
    text-align: left;
    font-size: 16px;
    position: relative;
    color: #fff;
    background: var(--card-color);
    padding: 4px 10px;
    border-radius: 20px;
    //&:not(:first-child) {
    //    position: relative;
    //    &:before {
    //        content: '';
    //        position: absolute;
    //        top: 50%;
    //        transform: translateY(-50%);
    //        bottom: 0;
    //        left: -8px;
    //        height: 40%;
    //        width: 1px;
    //        background-color: white;
    //    }
    //}
}

.socket-lobby-wrapper {
    position: relative;
}

.announcer-wrapper {
    gap: 8px;
    width: 30%;
    padding: 8px;
    padding-left: 24px;
    padding-right: 0;
    flex-direction: column;
    display: flex;
    max-height: -webkit-fill-available;
    background: #fffaf2;
    justify-content: flex-end;
    overflow: hidden;
    .announce {
        background: #fff;
        color: #334d56;
        border: 1px solid #334d56;
        box-shadow: 0 4px 0 0 #334d56;
        border-radius: 8px;
        padding: 8px;
        text-align: center;
        font-size: 1.25em;
        font-weight: 600;
        top: 90px;
        z-index: 1;
        width: 30%;
        position: absolute;
    }
    img {
        width: 100%;
        mix-blend-mode: darken;
    }
    &.not-empty {
        &:after {
            margin-left: -8px;
            content: '';
            z-index: 1;
            top: 0;
            position: absolute;
            background: rgb(255, 250, 242);
            background: linear-gradient(
                180deg,
                rgba(255, 250, 242, 1) 0%,
                rgba(255, 250, 242, 0) 100%
            );
            width: 100%;
            height: 20%;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-grab {
    cursor: grab;
}

.card-icon-wrapper {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%) translateY(-40px);
    height: 70%;
    img {
        height: 100%;
    }
}
