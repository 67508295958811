.video-player {
    position: relative;
    max-height: calc(70% - 203px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    video {
        width: 100%;
    }
    button {
        color: #fffaf2;
        background: #ff7c03;
        border: 1px solid #9d4c00;
        box-shadow: 0 4px 0 0 #9d4c00;
    }
    svg {
        width: 100%;
        fill: #fff2fa;
        transition: fill 0.2s linear 0s;
    }

    .video-bottom {
        position: absolute;
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.2s linear 0s, opacity 0.2s linear 0s;
        color: #fff;
        bottom: 30px;
        left: 30px;
        right: 30px;
        &::before {
            content: '';
            float: left;
            position: absolute;
            pointer-events: none;
            left: -30px;
            right: -30px;
            bottom: -30px;
            height: calc(100% + 120px);
            width: calc(100% + 60px);
        }
        .video-scrubber {
            position: relative;
            width: 100%;
            background: rgba(255, 255, 255, 0.15);
            margin-bottom: 15px;
            height: 6px;
            span {
                float: left;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                background: #fff;
                transform: scaleX(0); // naar 1
                transform-origin: left center;
            }
        }
        .video-controls {
            position: relative;
            display: flex;
            .controls-left {
                display: flex;
                align-items: center;
                .video-play {
                    svg {
                        height: 21.6666px;
                        width: 18px;
                        &.play-btn {
                            display: block;
                        }
                        &.pause-btn {
                            display: none; // swap met play
                        }
                    }
                }
                .video-audio {
                    margin-left: 30px;
                    svg {
                        width: 28px;
                        height: 18.6666px;
                        .sound-voluble {
                            display: block;
                        }
                        .sound-muted {
                            display: none;
                        }
                    }
                }
                .video-timer {
                    margin-left: 30px;
                    font-size: 1.25em;
                    .timer-duration {
                        opacity: 0.5;
                        &::before {
                            content: '/';
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
    .video-fullscreen {
        position: absolute;
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.2s linear 0s;
        right: 30px;
        top: 30px;
        width: 24px;
        height: 24px;
        svg {
            &.request {
                display: block;
            }
            &.exit {
                display: none;
            }
        }
    }
    &.mouse-idle {
        .video-fullscreen {
            opacity: 0;
        }
        .video-bottom {
            transform: translateY(calc(100% + 60px));
            opacity: 0;
        }
    }
    &.playing .video-overlay {
        display: none;
    }
}

.red-lines {
    border: 1px solid red;
}

.replay-button {
    position: absolute;
    bottom: 0;
    left: 25%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 0;
    padding: 0;
    color: #f29948;
}

.replay-icon {
    transform: initial !important;
}
.video-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: background 0.2s linear 0s;
    transform: translateY(90px);
    button {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        color: #fffaf2;
        background: #ff7c03;
        border: 1px solid #9d4c00;
        box-shadow: 0 4px 0 0 #9d4c00;
        border-radius: 100px !important;

        svg {
            fill: #fff2fa;
            transform: translateX(4px);
            width: 40px;
            height: 40px;
        }
    }
}
