.socket-hero-welcome {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    color: #345059;
    gap: 32px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    p {
        font-size: 1.75em;
        font-weight: 700;
    }
    .hero-text {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.5em;
        font-style: normal;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        justify-content: center;
        text-align: center;
        font-weight: 700;
    }
    .question-counter {
        position: absolute;
        top: 56px;
        left: 40%;
        text-align: center;
        font-weight: 600;
        transform: translate(-40%, 0);
        .counter-text {
            padding: 8px 16px;
            background: white;
            border-radius: 8px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            line-height: normal;
            position: absolute;
            right: -72px;
            bottom: 0px;
            span {
                line-height: normal;
            }
            span:first-child {
                font-weight: 900;
            }
            span:last-child {
                font-weight: 400;
            }
        }
        .counter-type {
            display: none;
            img {
                height: 56px;
            }
        }
    }
    .ptext {
        color: #345059;
        text-align: center;
        font-size: 1.3333em;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}
