input,
textarea,
select {
    appearance: none;
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 6px 8px;
}

select {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    position: relative;
    color: inherit;
    &.icon {
        padding-right: 28px;
    }
    &.dropdown {
        //background: url(/assets/images/icons/arrow-down.svg) no-repeat right center;
        background-size: 28px;
    }
}

.form-row {
    display: flex;
    label,
    input,
    textarea,
    select {
        width: 100%;
    }
    .form-field {
        padding-top: 15px;
        padding-bottom: 15px;
        position: relative;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        &.no-wrap {
            display: block;
        }
        &.no-width {
            width: auto;
        }
        .input-hint,
        .feedback {
            font-size: 0.7em;
        }
        .input-hint {
            opacity: 0.7;
        }
        p {
            color: #da2f2f;
        }
        &.is-invalid {
            input,
            textarea,
            select {
                border-color: var(--color-status-error);
            }
            .input-hint,
            label,
            .feedback {
                color: var(--color-status-error);
            }
            .input-hint {
                display: none;
            }
        }
        &.is-valid {
            input,
            textarea,
            select {
                border-color: var(--color-status-success);
            }
            .input-hint,
            .feedback,
            label {
                color: var(--color-status-success);
            }
        }
        &.inline {
            position: relative;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            img {
                max-width: 52px;
                max-height: 52px;
                border: 1px solid #bfbfbf;
            }
            label {
                flex-wrap: nowrap;
                display: flex;
            }
            .text {
                margin-left: 16px;
                display: flex;
                flex-wrap: wrap;
                > * {
                    width: 100%;
                    display: inline-block;
                    padding-bottom: 0;
                    margin-top: 0;
                }
            }
        }
    }
}

.form-section {
    width: 100%;
    .wrapper {
        width: 100%;
    }
}

// Margin settings for form elements
.form-row + .form-row,
.form-section + .form-section,
fieldset + fieldset,
.form-section + .form-row,
.form-row + .form-section {
    margin-top: var(--gutter);
    @media (min-width: 600px) {
        margin-top: calc(var(--gutter) / 2);
    }
}

[data-hint='true'] + * {
    margin-top: var(--gutter) !important;
}

// TODO: this should be revised
.input-hint + .feedback {
    margin-top: calc(var(--gutter) / 4);
    @media (min-width: 600px) {
        margin-top: calc(var(--gutter) / 8);
    }
}

input + .input-hint,
input + .feedback,
label + input,
input + .notification,
label + .form-row,
label + textarea,
label + select {
    margin-top: calc(var(--gutter) / 4);
    @media (min-width: 600px) {
        margin-top: calc(var(--gutter) / 8);
    }
}
.form-field + .form-field {
    margin-left: var(--gutter);
    @media (min-width: 600px) {
        margin-left: calc(var(--gutter) / 2);
    }
}

.input-hint,
.feedback {
    margin-bottom: calc(var(--gutter) / 4 + -1.5em);
    @media (min-width: 600px) {
        margin-bottom: calc(var(--gutter) / 8 + -1.5em);
    }
}

.label-hint {
    font-size: 0.7em;
}

.form-section {
    button {
        margin: 40px auto 0;
        width: calc(100% / 3 - 16px);
    }
    h1 {
        text-align: center;
        margin-bottom: 48px;
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        .form-field {
            width: 100%;
            position: relative;
            p {
                position: absolute;
                bottom: -20px;
                font-size: 12px;
                text-align: right;
                right: 0;
            }
            label {
                display: block;
                font-size: 19px;
                margin-bottom: 8px;
                font-weight: 600;
            }
            input, select {
                width: 100%;
                padding: 16px;
                font-size: 19px;
                border-radius: 8px;
                border: 1px solid #efefef;
                display: inline-block;
                appearance: auto;
            }
            @media (min-width: 600px) {
                width: calc(100% / 2 - 16px);
            }
            @media (min-width: 900px) {
                width: calc(100% / 3 - 16px);
            }
        }
    }
    .image-wrapper {
        background: #fffaf2;
        position: absolute;
        top: 60%;
        width: 100%;
        left: 50%;
        transform: translateY(-57%) translateX(-50%);
        z-index: -1;
        img {
            width: 100%;
            mix-blend-mode: darken;
            object-fit: contain;

        }
    }
}