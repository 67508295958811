.guess-responses-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.guess-number-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 24px;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.guess-number-box {
    display: flex;
    width: 120px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &.span {
        width: 240px;
    }
}

.guess-number-box-empty {
    display: flex;
    width: 130px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.guess-min-button {
    width: 130px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgb(91, 91, 91);
    background: rgb(242, 242, 242);
    color: rgb(91, 91, 91);
    box-shadow: rgb(59, 59, 59) 0px 2px 0px 0px;
    svg {
        padding: 8px;
        height: 100%;
    }
}

//make flicker effect
@keyframes flicker {
    0% {
        color: rgba(255, 255, 255, 1);
    }
    49% {
        color: rgba(255, 255, 255, 1);
    }
    50% {
        color: rgba(255, 255, 255, 0);
    }
    100% {
        color: rgba(255, 255, 255, 0);
    }
}

.guess-input {
    width: 100%;
    height: 80px;
    border: 0px;
    display: flex;
    text-align: right;
    font-family: 'Open Sans', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 16px;
    padding: 16px 24px;
    &.flicker {
        animation: flicker 1s infinite;

    }
}

.guess-button {
    width: calc(100% - 48px);
    display: block;
    border-radius: 8px;
    margin: 0 24px;
    margin-bottom: 16px;
    background: #FF7C03 !important;
    color: white !important;
    border: 1px solid #934700;
    box-shadow: 0px 2px 0px 0px #934700 !important;
}