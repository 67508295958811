#root {
    overflow: hidden;
    height: 100dvh;
}

:root {
    --layout-gap: 2rem; //32px
    @media (min-width: 1600px) {
        --layout-gap: 3rem; //48px
    }
}

body {
    color: #334d56;
    font-family: 'Open Sans', serif;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
