.transition-wrapper {
    width: 100%;
    height: 330px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    &.beslissingsvraag {
        background: url('../../../../../public/assets/vragen/beslissingsvraag.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    &.gesloten-vraag {
        background: url('../../../../../public/assets/vragen/geslotenvraag.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    &.meerkeuzevraag {
        background: url('../../../../../public/assets/vragen/meerkeuzevraag.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    &.open-vraag {
        background: url('../../../../../public/assets/vragen/openvraag.png') no-repeat;
        background-size: contain;
        background-position: center;
        transform: translateX(-80px);
        .current-question-wrapper {
            right: -220px !important;
        }
        .quizmaster-wrapper {
            transform: translateX(80px);
        }
    }
    &.schattingsvraag {
        background: url('../../../../../public/assets/vragen/schattingsvraag.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    &.stelling {
        background: url('../../../../../public/assets/vragen/stelling.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    .quizmaster-wrapper {
        bottom: 0;
        position: absolute;
    }
    .current-question-wrapper {
        padding: 0px 32px;
        background: white;
        border-radius: 16px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        font-size: 4rem;
        position: absolute;
        right: -120px;
        bottom: 70px;
        width: 220px;
        text-align: center;
        justify-content: center;
    }
}
