.flex {
    display: flex;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.flex-col {
    flex-direction: column;
}

.flex-col-reverse {
    flex-direction: column-reverse;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.items-center {
    align-items: center;
}

.block {
    display: block;
}

.inner {
    padding: 16px;
}

.h-full {
    height: 100%;
}

.w-full {
    width: 100%;
}

._size-16 {
    width: 16px;
    height: 16px;
}

._size-32 {
    width: 32px;
    height: 32px;
}

.pl-24 {
    padding-left: 24px;
}

.w-1_10 {
    width: 10%;
}

.w-2_10 {
    width: 20%;
}

.w-3_10 {
    width: 30%;
}

.w-4_10 {
    width: 40%;
}

.w-5_10 {
    width: 50%;
}

.w-6_10 {
    width: 60%;
}

.w-7_10 {
    width: 70%;
}

.w-8_10 {
    width: 80%;
}

.w-9_10 {
    width: 90%;
}

.w-10_10 {
    width: 100%;
}

.w-1 {
    width: 1px;
}

.w-2 {
    width: 2px;
}

.w-3 {
    width: 3px;
}

.w-4 {
    width: 4px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-8 {
    margin-left: 8px;
}

.ml-16 {
    margin-left: 16px;
}

.ml-24 {
    margin-left: 24px;
}

.ml-32 {
    margin-left: 32px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-48 {
    margin-left: 48px;
}

.ml-56 {
    margin-left: 56px;
}

.ml-64 {
    margin-left: 64px;
}

.mr-4 {
    margin-right: 4px;
}

.mr-8 {
    margin-right: 8px;
}

.mr-16 {
    margin-right: 16px;
}

.mr-24 {
    margin-right: 24px;
}

.mt-auto {
    margin-top: auto;
}

.mr-auto {
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.mr-32 {
    margin-right: 32px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-48 {
    margin-right: 48px;
}

.mr-56 {
    margin-right: 56px;
}

.mr-64 {
    margin-right: 64px;
}

.mt-4 {
    margin-top: 4px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-48 {
    margin-top: 48px;
}

.mt-56 {
    margin-top: 56px;
}

.mt-64 {
    margin-top: 64px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-48 {
    margin-bottom: 48px;
}

.mb-56 {
    margin-bottom: 56px;
}

.mb-64 {
    margin-bottom: 64px;
}

.mb-128 {
    margin-bottom: 128px;
}

.gap-4 {
    gap: 4px;
}

.gap-8 {
    gap: 8px;
}

.gap-16 {
    gap: 16px;
}

.opacity-10 {
    opacity: 0.1;
}

.opacity-20 {
    opacity: 0.2;
}

.opacity-30 {
    opacity: 0.3;
}

.opacity-40 {
    opacity: 0.4;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-60 {
    opacity: 0.6;
}

.opacity-70 {
    opacity: 0.7;
}

.opacity-80 {
    opacity: 0.8;
}

.opacity-90 {
    opacity: 0.9;
}

.opacity-100 {
    opacity: 1;
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    &.s {
        max-width: 680px;
    }
    &.m {
        max-width: 900px;
    }
    &.l {
        max-width: 1200px;
    }
}

.space {
    &.s {
        gap: 8px;
    }
    &.m {
        gap: 16px;
    }
    &.l {
        gap: 32px;
    }
}

.padding {
    &.s {
        padding: 8px;
    }
    &.m {
        padding: 16px;
    }
    &.l {
        padding: 32px;
    }
}

.margin {
    &.top-16 {
        margin-top: 16px;
    }
}

.invisible {
    visibility: hidden;
}

.priority-100 {
    z-index: 100;
}

.relative {
    position: relative;
}

.cursor-default {
    cursor: default !important;
}

.absolute {
    position: absolute;
}

.top-52 {
    top: 52px;
}

.bg-white {
    background-color: #e5e5e5;
}

.bg-nassau {
    background-color: #ff7c03;
}

.px-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.z-999 {
    z-index: 999;
}
