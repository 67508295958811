button {
    border: 0;
}

.additional-text {
    position: absolute;
    top: -32px;
}

.button {
    font-family: 'Open Sans', serif;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 40px;
    gap: 16px;
    border-bottom-style: solid;
    border-bottom-width: 6px;
    cursor: pointer;
    font-size: 22px;
    border-radius: 40px;
    font-weight: 700;
    &.primary {
        color: #fffaf2;
        background: #ff7c03;
        border: 1px solid #9d4c00;
        box-shadow: 0 4px 0 0 #9d4c00;
        scale: 1;
        transition: background .2s linear, scale .2s linear;
        &.cq23:hover {
            background: #305ec4;
            scale: 1.02;
        }
        &:hover , &.extra-color.cq23:hover{
            background: #fd6c0c;
            scale: 1.02;
        }
        &.extra-color.cq23:hover{
            opacity: 1;
        }
        &-disabled {
            cursor: not-allowed;
            opacity: 0.8;
        }
    }
    &.cq23 {
        background: #2637ff;
        border: 1px solid #000650;
        box-shadow: 0 4px 0 0 #00064a;
    }
    &.extra-color.cq23 {
        background: #fd6c0c;
        border: 1px solid #9d4c00;
        box-shadow: 0 4px 0 0 #9d4c00;
        opacity: 0.6;
    }
    &.disabled {
        border: 1px solid #000;
        color: rgba(0, 0, 0, 0.67);
        white-space: nowrap;
        padding: 16px 40px;
    }
    &.text {
        color: #ffffffee;
        font-weight: 700;
    }
    &.text-disabled {
        width: 75px;
        height: 30px;
        font-style: normal;
        line-height: 30px;
        color: #f8f5f2;
    }
    &.back,
    &.inactive {
        background: #fdfbfe;
        color: #334d56;
        border: 1px solid #334d56;
        box-shadow: 0 4px 0 0 #334d56;
        transition: background .2s linear, scale .2s linear;
        &:hover {
            background: #fdf9ff;
            scale: 1.02;
        }
    }
    &.transparent {
        background: transparent;
        color: #334d56;
        border: 1px solid #334d56;
        box-shadow: 0 4px 0 0 #334d56;
    }
}

.x-button {
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    float: right;
}

.text-link {
    background: transparent;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #234559;
}
