.info-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    height: fit-content;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: var(--layout-gap);
    font-size: 24px;
    color: #050505;
    @media(min-width: 1600px) {
        font-size: 32px;
    }
}

.question-intro-text {
    width: 60%;
    height: 50%;
    border-radius: 4px;
    background: #fffffc;
    box-shadow: 4px 6px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Open Sans', sans-serif;}

.question-text {
    width: 60%;
    height: 50%;
    border-radius: 4px;
    background: #fffffc;
    box-shadow: 4px 6px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Open Sans', sans-serif;}
