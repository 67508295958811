.side-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    height: 100vh;
    background-color: rgb(4, 13, 13);
    color: white;
    padding: 90px 30px 30px 30px;
    max-height: 100%;
    width: 300px;
}

.side-menu.active {
    transform: translateX(0%);
    transition: transform 0.5s ease-out;
}
