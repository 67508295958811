.open-questions-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
}

.open-questions-text {
    height: 2em;
    font-family: 'Open Sans', sans-serif;    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    text-align: left;
    margin-bottom: 24px;
}

.open-response-text {
    padding-left: 16px;
    border-left: 2px solid #334D56;
    font-style: italic;
}

.open-numberboxes-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.open-numberbox {
    background-color: blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 85px;
    height: 85px;
    border-radius: 4px;
    box-shadow: 3px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.open-anwser-reveal {
    position: absolute;
    background-color: rgb(253, 251, 254);
    font-weight: 500;
    border: 1px solid #334D56;
    border-radius: 8px;
    z-index: 1;
    top: 40px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    transform: scale(0);
    transform-origin: top left;
    &.open {
        transform: scale(1);
    }
}

.open-anwser-reveal-text {
    padding: 16px;
}

.open-anwser-reveal-button {
    right: -48px;
    position: absolute;
    background: rgb(253, 251, 254);
    border-radius: 24px;
    width: 32px;
    height: 32px;
    overflow: hidden;
    color: rgb(51, 77, 86);
    box-shadow: rgb(51, 77, 86) 0px 4px 0px 0px;
    border: 1px solid rgb(51, 77, 86);
    span {
        text-align: center;
        font-size: 19px;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        font-weight: 600;
        transition: transform  0.2s ease-in-out;
    }
    &.open {
        .open-button {
            transform: translateY(-100%);
        }
        .close-button {
            transform: translateY(0%);
        }
    }
    .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(100%);
    }
}