.warning-button {
    font-family: 'Epilogue', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #2b531e;
    border: solid 1px #faf3ba;
    border-radius: 25px;
    color: #f3f7f2;
    padding: 5px;
    font-weight: bold;
    font-size: 1em;
    font-size: 1.5em;
    min-height: 195px;
    max-height: 15%;
    min-width: 480px;
    max-width: 13%;
    &.accept {
        background-color: #2b531e;
    }
    &.reject {
        background-color: #7e2a17;
    }
    &.accept:hover {
        background-color: #1b3711;
    }
    &.reject:hover {
        background-color: #4b160a;
    }
}
