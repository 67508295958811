.announcer {
    font-weight: 600;
    border-radius: 12px;
    padding: 12px 24px;
    width: fit-content;
    border: 1px solid #787171;
    font-size: 1.05em;
    &:nth-child(odd) {
        border-bottom-right-radius: 0;
    }
    &:nth-child(even) {
        border-bottom-left-radius: 0;
    }
}
