.question-response-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    &.medium {
        max-width: 800px;
    }
    &.wide {
        max-width: 800px;
    }
    &.xsmall {
        max-width: 475px;
    }
    &.guess {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 40px;
        max-width: 860px;
        > div {
            width: 100%;
        }
    }
}

.question-response-responses-wrapper {
    &.open {
        margin-top: 24px;
    }
    &.guess {
        margin-top: 16px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
    width: 100%;
}

.question-response-question-subtext {
    margin-bottom: 8px;
}

.question-response-question-text {
    font-family: 'Epilogue', sans-serif;
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    font-size: 40px;
    @media (min-width: 1600px) {
        font-size: 40px;
    }
}

.wide-wrapper {
    width: 100%;
}

.question-text-wrapper {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &.medium {
        max-width: 800px;
    }
    &.wide {
        max-width: 1000px;
    }
}

.intro-wrapper {
    max-width: 50%;
}

.question-images-wrapper {
    flex-shrink: 0;
    overflow: hidden;
    object-fit: contain;
}
