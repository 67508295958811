.question-answer-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overflow-hidden {
    overflow: hidden;
}
