.big-input {
  font-size: 2.5rem;
  margin: auto;
  width: 250px;
  height: 75px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2%;
  border-color: #000000;
  text-align: center;
  font-family: 'Source Sans Pro';
}
