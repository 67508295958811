.school-title {
    text-align: left;
    position: relative;
    bottom: 0;
    width: 100%;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    @media (max-width: 768px) {
        font-size: 18px;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 48ch;
    margin-right: auto;
}

.tag {
    font-size: 16px;
    white-space: nowrap;
    font-family: 'Epilogue', sans-serif;
}

.list-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
    position: relative;
    overflow: auto;
    background: #ffffff;
    border-radius: 16px;
    border: black solid 1px;
}

.selection-wrapper-kek {
    margin-bottom: 0.75rem;
    height: 48px;
}

.selection-wrapper {
    width: 100%;
    max-width: 800px;
}

.selection-button {
    height: 100%;
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    border: 1px solid black;
    padding: 0.75rem 2rem;
    font-size: 18px;
    &.active {
        background-color: rgb(22 145 190);
        box-sizing: border-box;
        color: rgb(255 255 255);
    }
    &.inActive {
        cursor: pointer;
        background-color: #ffffff;
        box-sizing: border-box;
    }
}
