.timer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    min-width: 240px;
    padding: 16px 24px;
    border-radius: 16px;
    position: absolute;
    bottom: var(--layout-gap);
    left: 50%;
    transform: translateX(-50%) scale(1.5);
    transform-origin: center bottom; 
}

.white-bar {
    background-color: #e5e5e5;
    height: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
    border-radius: 8px;
    //border: 1px solid #334d56;
}

.progress-wrapper {
    margin-top: 4px;
    position: relative;
    width: 100%;
    height: 8px;
    border-radius: 8px;
    background-color: #334d56;
}

.progress-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fde534;
    width: 80%;
    height: 8px;
}

//demo
.question {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 8em;
    .image-wrapper {
        display: flex;
        justify-content: flex-end;
        position: relative;
        .mask {
            overflow: hidden;
            display: inline-block;
            line-height: 0;
            &.bag {
                position: absolute;
                bottom: 0;
            }
        }
        img {
            object-fit: contain;
        }
    }
    .col {
        width: 100%;
    }
    h1 {
        font-size: 4.5em;
        line-height: 1.2;
        max-width: 600px;
    }
}
