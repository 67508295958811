.question-display-wrapper {
    width: 100%;
    text-align: left;
    position: relative;
    h1 {
        margin-bottom: 32px;
    }
    &:after {
        display: block;
        position: absolute;
        content: '';
        width: 2px;
        height: 0%;
        animation: full-height 0.3s ease-in-out forwards;
        animation-delay: 0.3s;
        transform-origin: bottom;
        left: -2px;
        bottom: 0;
        border-radius: 2px;
    }
    @keyframes full-height {
        0% {
            height: 0%;
        }
        100% {
            height: 100%;
        }
    }
}
