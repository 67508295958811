.closed-options-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 16px;
    margin-top: 32px;
}

.closed-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 50%;
    font-weight: 700;
    border-radius: 8px;
    padding: 24px 32px;
    line-height: inherit;
    font-size: 24px;
    @media(min-width: 1600px) {
        font-size: 32px;
    }
}
