.tips-text-wrapper {
    font-weight: 600;
    color: rgb(52, 80, 89);
}

.tips-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: auto;
    padding-right: 16px;
    border-right: 1px solid rgb(113, 157, 168);
    .tips-text-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    svg {
        height: 16px;
        // border: 1px solid rgb(113, 157, 168);
        border-radius: 50%;
        .cls-1 {
            fill: rgb(52, 80, 89);
        }
        .cls-2 {
            fill: #fff;
        }
    }
}

.footer-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-end;
    &.right-align {
        min-height: 54px;
        margin-left: auto;
        justify-content: flex-end;
    }
    &.left-align {
        margin-right: auto;
        justify-content: flex-start;
    }
}
