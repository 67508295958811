.spline {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 0;
    canvas {
        height: 100% !important;
        width: 100% !important;
        object-fit: contain;
    }
}
