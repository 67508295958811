.startcode-wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  h5 {
    line-height: normal;
    font-size: inherit;
    font-family: "Open Sans", sans-serif;
  }
  label {
    font-size: 2em;
    margin-bottom: .5rem;
  }
  input {
    width: 100%;
  }
}

.input-wrapper {
  .form-field {
    .error-message {
      margin-top: 4px;
      margin-left: 0.2em;
      font-size: 1em;
      line-height: 1.5em;
      display: block;
        color: rgb(255 30 3);
    }
    input {
      border: 1px solid #d8d8d8;
      color: #28434b;
    }
  }
  input {
    font-size: 1.5em;
    border-radius: 24px;
    background: #fff;
    padding: 16px 24px;
    font-weight: 400;
    border: 1px solid #eeeeee;
    border-bottom-width: 2px;
    &::placeholder {
      color: #B3B3B3;
    }
  }
  .button {
    font-size: 1.5em;
    padding: 8px 40px;
    border-radius: 24px;
  }
}

.no-code-wrapper {
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  h5 {
    width: 100%;
  }
  .wrapper {
    margin: 0;

  }
  .vertical-line {
    width: 1px;
    height: 100%;
    background: rgb(216, 216, 216);
  }
  .text-link {
    color: #334D56;
    font-weight: 200;
    font-size: 1.25rem;
    display: inline;
    position: relative;
    &:not(:last-child) {
      margin-right: 16px;
      &:after {
        right: -16px;
        top: 3px;
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        background: #B3B3B3;
      }
    }
  }
}