.mockMobileWrapper {
    position: relative;
    border-left: 4px solid #000;
    border-right: 4px solid #000;
    overflow: hidden;
}

.mockMobileEdge {
    position: relative;
    background: #000;
    &.top {
        border-top: 20px solid #000;
        border-radius: 20px 20px 0 0;
        height: 35px;
    }
    &.bottom {
        border-bottom: 20px solid #000;
        border-radius: 0 0 20px 20px;
        height: 20px;
    }
}

.hitbox {
    z-index: 99;
    opacity: 0.2;
}

.topLeft {
    top: 0;
    left: 0;
}

.mockMobileImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
