.score-bottom {
    position: absolute;
    bottom: 0;
    height: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--layout-gap);
}

.score-bar-wrapper {
    position: absolute;
    bottom: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    width: 100%;
    height: 80%;
}

.score-bar {
    overflow: visible;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 120px;
    height: 120px;
    padding: 24px;
    align-items: center;
    &.reveal {
        border-radius: 16px 16px 0 0;
        //box-shadow: -2px -1px 0px 2px;
        border: #334d56 1px solid;
        border-bottom-width: 0;
    }
    &.aReveal {
        border: #334d56 1px solid;
        border-radius: 16px;
        position: flex;
    }

    .team-number {
        font-size: 32px;
        font-weight: 500;
        font-family: 'Epilogue', sans-serif;
        display: inline-block;
        transform: rotate(5deg) translateY(4px);
    }
    .team-display {
        margin-top: auto;
    }
}

.score-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    &.bottom {
        bottom: 0;
    }
    &.center {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: initial;
        .score-bar-wrapper {
            position: initial;
            height: initial;
            .score-bar-new {
                height: inherit;
            }
        }
    }
}

.score-number-display {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.score-number-coin {
    position: absolute;
    bottom: 100%;
    height: 300px;
    overflow: hidden;
    width: 100%;
    left: 0;
    line-height: 0;
    //div without class name selector
    > div:not([class]) {
        position: relative;
        bottom: -45px;
    }
    span {
        position: relative;
        bottom: 0;
        display: block;
        width: 48px;
        height: 48px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.team-display {
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.confetti-wrapper {
    overflow: visible;
    z-index: -1;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;
}

.score-number {
    font-size: 24px;
    text-align: center;
    font-family: 'Epilogue', sans-serif;
    z-index: 10;
    svg {
        width: 12px;
    }
}

.display-wrapper {
    position: absolute;
    width: 128px;
    &.translateY-50 {
        transform: translateY(50px);
    }
    &.translateY-80 {
        transform: translateY(80px);
    }
    &.translateY-100 {
        transform: translateY(100px);
    }
    &.translateY-110 {
        transform: translateY(110px);
    }
    &.translateY-130 {
        transform: translateY(130px);
    }
    .no-break {
        font-size: 18px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.score-number-team-color {
    line-height: normal;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    font-family: 'Epilogue', sans-serif;
    z-index: 10;
}
.score-wrapper {
    line-height: initial;
    font-size: 28px;
    padding-top: 8px;
    padding-bottom: 4px;
    font-weight: 600;
    text-align: center;
    font-family: 'Epilogue', sans-serif;
    background: #fff;
    color: #334d56;
    //box-shadow: 0 4px 0 0 #334d56;
    border-radius: 16px;
}

.score-bar-new {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    &.reveal {
        .score-bar {
            &:first-child {
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    left: -50%;
                    width: 200vw;
                    height: 1px;
                    background: #334d56;
                    transform: translateX(-50%);
                }
            }
        }
    }
}

.score-non-black {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
}

.score-bar canvas{
    margin-left:-60px;
    margin-top:-60px;
}