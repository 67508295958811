.team-selection-wrapper {
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    height: 10vh;
}

.team-selection-box-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 20px;
    width: 40px;
    height: 40px;
    @media (min-width: 1280px) {
        width: 64px;
        height: 64px;
    }
    border-radius: 16px;
}
// Styling stelling
.team-selection-box-wrapper-thesis{
 
    .team-response{
        font-size:10px;
    }
}

.team-inner-wrapper {
    width: 100%;
    gap: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: fit-content;
}

.team-selection-bar {
    bottom: -16px;
    position: absolute;
    height: 6px;
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
}

.team-response {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    scale: 0.8;
    @media (min-width: 1280px) {
        scale: 1;
    }
}

.team-text {
    z-index: 10;
    font-family: 'Epilogue', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    @media (min-width: 1280px) {
        font-size: 16px;
    }
}

.column-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
