.qr-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    padding: calc(var(--layout-gap) * 4);
    //width: calc(100% - var(--layout-gap) * 4);
    //height: calc(100% - var(--layout-gap) * 4);
    //position: fixed;
    //top: calc(var(--layout-gap) * 2);
    //left: calc(var(--layout-gap) * 2);
    //right: calc(var(--layout-gap) * 2);
    //bottom: calc(var(--layout-gap) * 2);
    //z-index: 9999;
    //display: flex;
    //align-items: center;
    .qr-enlarged {
        width: calc(70% - 1px);
        gap: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(247, 246, 246, 0);
        border: 2px solid rgba(247, 246, 246, 0);
    }
}
