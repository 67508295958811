.warning-pop-up {
    font: 1.5rem 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
}
// cypress.css
.warning-pop-up-wrapper {
    // opacity: 0.001;
    position: fixed;
    box-sizing: border-box;
    inset: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.95);
    color: rgb(232, 232, 232);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    z-index: 10;
}

.warning-pop-up-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.warning-pop-up-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #3e0707;
    border: 2px solid #ffffff;
    color: #f3f7f2;
    padding: 55px 75px;
    font-weight: bold;
    font-size: 4em;
    min-height: 75px;
    max-height: 15%;
    min-width: 75px;
    max-width: 15%;
    animation: moveAnimation 2s alternate infinite;
}

.absolute-popup {
    min-width: 600px;
    justify-content: flex-start;
    .close {
        right: 24px !important;
        top: 24px !important;
        width: 16px !important;
        height: 16px !important;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .text-wrapper {
        h2 {
            margin-bottom: 1rem;
        }
        p {
            font-size: 18px;
            list-style:disc inside none;
            display:list-item;
        }
    }
}

@keyframes moveAnimation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(
            10px
        ); /* Change this value to adjust the vertical movement */
    }
}
