.wa-wrapper {
    position: relative;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background: #000;
    .wa-logo {
        position: absolute;
        width: 64px;
        height: 64px;
        background: url('../../../public/assets/whatsApp/wa-logo.png') no-repeat;
        background-size: 100% auto;
        top: 50%;
        left: 50%;
        margin-left: -32px;
        margin-top: -32px;
        transition: opacity 0.3s linear 0s, transform 0.3s ease 0s;
        opacity: 1;
        transform: scale(1);
        &.click {
            opacity: 0;
            transform: scale(1.2);
        }
    }
    .wa-ui {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #fffaf2;
        transition: opacity 0.1s linear 0.1s, transform 0.3s ease 0.1s;
        transform: scale(0.2);
        opacity: 0;
        position: relative;
        &.open {
            opacity: 1;
            transform: scale(1);
        }
        .wa-header {
            background: #387f6a;
            font-size: 0.9em;
            line-height: 1.2;
            padding: 16px;
            color: #fff;
            .wa-avatar {
                display: flex;
                align-items: center;
                .wa-thumb {
                    width: 48px;
                    height: 48px;
                    background: #fff;
                    border-radius: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                > div {
                    margin-left: 16px;
                    .wa-name {
                        display: inline-block;
                        font-weight: 700;
                        width: 100%;
                    }
                    .wa-status {
                        font-size: 0.9em;
                    }
                }
            }
        }
        .wa-body {
            padding: 16px;
            display: flex;
            flex-direction: column;
            .wa-date {
                display: flex;
                justify-content: center;
                padding-bottom: 16px;
                span {
                    font-size: 0.9em;
                    background: #fff;
                    border-radius: 8px;
                    padding: 4px 12px;
                    border: 1px solid #ccc8c2;
                    box-shadow: 0 2px 0 rgb(204, 200, 194, 0.5);
                }
            }
            .msg-group {
                display: flex;
                flex-direction: column;
                width: 100%;
                + .msg-group {
                    margin-top: 8px;
                }
                > div {
                    max-width: 66%;
                    + div {
                        margin-top: 4px;
                    }
                    .msg {
                        display: inline-block;
                        border: 1px solid #ccc8c2;
                        box-shadow: 0 1px 0 rgb(204, 200, 194, 0.5);
                        padding: 4px 12px;
                        border-radius: 12px;
                        max-width: 100%;
                        transition: opacity 0.3s linear 0s,
                            transform 0.3s cubic-bezier(0.25, 0.05, 0.1, 0.94)
                                0s;
                        opacity: 0;
                        transform: translateY(30px);
                        &.show {
                            opacity: 1;
                            transform: translateY(0);
                        }
                        > span.emoji {
                            display: inline-block;
                            width: 1em;
                            height: 1em;
                            bottom: -2px;
                            position: relative;
                            background-size: contain !important;
                            &.call-me {
                                background: url('../../../public/assets/whatsApp/call-me-hand_1f919.png');
                            }
                            &.money-mouth {
                                background: url('../../../public/assets/whatsApp/money-mouth-face_1f911.png');
                            }
                        }
                        .msg-attachment {
                            background: #f7f7f7;
                            width: calc(100% + 16px);
                            min-height: 80px;
                            margin-left: -8px;
                            border-radius: 8px;
                            overflow: hidden;
                            .attachment-image {
                                width: 100%;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .attachment-meta {
                                padding: 4px 12px;
                                font-size: 0.8em;
                                line-height: 1.4;
                                .meta-title {
                                    font-weight: 700;
                                }
                                .meta-desc {
                                    overflow: hidden;
                                    max-width: 100%;
                                    text-overflow: ellipsis;
                                    span {
                                        overflow: hidden;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                        .msg-inline-link {
                            color: #53bdeb;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        .msg-time {
                            display: inline-flex;
                            color: rgba(0, 0, 0, 0.5);
                            font-size: 0.7em;
                            padding-left: 5px;
                            align-items: center;
                        }
                    }
                }
                &.msg-in {
                    > div {
                        margin-right: auto;
                        .msg {
                            background: #fff;
                        }
                        &:last-child {
                            .msg {
                                border-radius: 12px 12px 12px 0;
                            }
                        }
                    }
                }
                &.msg-out {
                    > div {
                        margin-left: auto;
                        .msg {
                            background: #ebfede;
                            .msg-status {
                                display: flex;
                                &.status-read {
                                    margin-left: 4px;
                                    background: url('../../../public/assets/whatsApp/checkmark-blue.svg')
                                        no-repeat;
                                    width: 16px;
                                    height: 8px;
                                }
                            }
                        }
                        &:last-child {
                            .msg {
                                border-radius: 12px 12px 0 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .wa-drawer {
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: column;
        transition: transform 0.2s ease 0s;
        &.open {
            transform: translateX(-100%);
        }
        .drawer-header {
            padding: 16px 24px;
            background: #444;
            color: #fff;
            position: relative;
            .drawer-back-btn {
                color: #53bdeb;
            }
            .drawer-title {
                font-weight: 700;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .drawer-body {
            height: 100%;
            overflow: auto;
            img.hero {
                width: 100%;
                height: auto;
            }
            .drawer-content {
                padding: 0 16px 16px;
                .puristic-form {
                    border-top: 1px solid #bfbfbf;
                    border-bottom: 1px solid #bfbfbf;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    label {
                        margin-bottom: 16px;
                        display: inline-block;
                    }
                    button {
                        font: inherit;
                        width: 100%;
                        padding: 4px 16px;
                        background: #eee;
                        border: 1px solid #bfbfbf;
                        border-radius: 4px;
                        + button {
                            margin-top: 8px;
                        }
                    }
                    .form-submit {
                        margin-top: 16px;
                        button {
                            background: #259f00;
                            border: 1px solid #1e8100;
                            color: #fff;
                            padding: 8px 16px;
                            font-size: 1.05em;
                            font-weight: 700;
                        }
                    }
                }
                .reviews {
                    .review-users {
                        border-top: 1px solid #bfbfbf;
                        margin-top: 16px;
                        padding-top: 16px;
                    }
                    .review {
                        .stars {
                            display: flex;
                            align-items: center;
                            font-size: 0.85em;
                            .star {
                                width: 16px;
                                height: 16px;
                                background: url('../../../public/assets/whatsApp/star.svg')
                                    no-repeat;
                                background-size: 100% auto;
                                margin-right: 5px;
                                &.empty {
                                    background: url('../../../public/assets/whatsApp/star-empty.svg')
                                        no-repeat;
                                    background-size: 100% auto;
                                }
                            }
                        }
                        .review-avatar {
                            display: flex;
                            align-items: center;
                            margin-bottom: 8px;
                            .review-thumb {
                                width: 40px;
                                height: 40px;
                                background: #fff;
                                border-radius: 100%;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .review-name {
                                margin-left: 12px;
                            }
                        }
                        .review-details {
                            h3 {
                                margin: 4px 0 0;
                                font-size: 1em;
                            }
                            p {
                                margin: 0 0 4px;
                                font-size: 1em;
                            }
                        }
                        .review-useful {
                            color: #666;
                            font-size: 0.85em;
                            margin-bottom: 8px;
                        }
                        .review-count {
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
    &.desktop {
        max-width: 50%;
        top: 50%;
        transform: translateY(-50%);
        height: 80%;
        background: transparent;
        .wa-info-wrapper {
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            width: 50%;
            align-content: center;
            .wa-name {
                font-size: 1.75em !important;
                line-height: normal;
                width: fit-content !important;
                display: block !important;
            }
            .wa-status {
                white-space: nowrap;
                font-size: 1.75em !important;
                display: block !important;
                width: fit-content !important;
                line-height: normal;
            }
        }
        .wa-drawer {
            display: none;
        }
        .wa-ui {
            .wa-header {
                padding: 0;
                background: 0;
                color: #334d56;
                margin-left: calc(-45px + -16px);
            }
            .wa-body {
                padding: 16px 0;
                .msg {
                    font-size: 1.75em;
                }
                .msg-group.msg-in > div .msg:not(.msg-has-att) {
                    padding: 12px 22px;
                }
                .msg-inline-link {
                    display: block;
                    padding: 6px 12px;
                    padding-top: 6px;
                }
                .msg-group > div {
                    max-width: 80%;
                    .msg {
                        .msg-time {
                            display: none;
                        }
                        .msg-attachment {
                            display: flex;
                            .attachment-meta {
                                width: 70%;
                            }
                        }
                    }
                }
                .wa-date {
                    display: none;
                }
            }
        }
    }
}

.demo-grand-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.demo-other-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.demo-result-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}

.demo-result-box {
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 130px;
    max-width: 100px;
    text-align: center;
    color: #3a4f58;
}

.demo-result-letter-box-wrapper {
    height: 130px;
    width: 100px;
    border: 2px solid #d9cfb8;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom: 0px;
    font-size: 2.5em;
}

.demo-result-letter-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800;
}

.demo-result-index-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 2px solid;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    font-size: 1.5em;
    color: white;
    font-weight: 700;
    p {
        text-shadow: #404040 1px 1px 0px;
    }
}
