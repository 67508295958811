.mpc-options-wrapper {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 16px;
    margin-top: 24px;
    &.response {
        gap: 16px;
        width: 100%;
        flex-direction: row;
        min-height: 120px;
        min-width: 180px;
        max-height: 120px;
        font-weight: 900;
    }
}

.mpc-options-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 24px;
    .mpc-option {
        height: 100%;
    }
}

.mpc-option {
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    //background: white;
    min-height: 88px;
    height: fit-content;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    gap: 24px;
}

.mpc-letter {
    font-weight: 800;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 16px;
    aspect-ratio: 1 / 1;
    font-family: 'Epilogue', sans-serif;
    font-size: 32px;
    line-height: normal;
    span {
        margin-bottom: -6px;
    }
}

.bottom-0 {
    bottom: 0;
}

.right-0 {
    right: 0;
}
