.centered-pop-up {
    position: fixed;
    box-sizing: border-box;
    inset: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.5);
    color: #345059;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .blur-background {
        position: absolute;
        inset: 0px;
        width: 100vw;
        height: 100vh;
        background: rgb(255 255 255 / 0%);
        backdrop-filter: blur(15px);
        z-index: -1;
    }
    .wrapper {
        gap: 24px;
        max-height: 600px;
        overflow: hidden;
        position: relative;
        background: #fff;
        box-shadow: 0 4px 0 0 #334d56;
        padding: 64px;
        border-radius: 16px;
        border: 1px solid #334d56;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .button-wrapper {
            display: flex;
            gap: 16px;
        }
        h2 {
            line-height: normal;
            width: 100%;
        }
        ul {
            padding: 0;
            font-size: 1.25em;
            li:not(:first-child) {
                margin-top: 8px;
            }
        }
        .close {
            cursor: pointer;
            position: absolute;
            top: 32px;
            right: 32px;
            width: 31px;
            height: 29px;
        }
    }
}
