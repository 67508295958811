.winner-screen {
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;
    max-width: 1000px;
    align-items: flex-end;
    .id-wrapper,
    .position-wrapper span {
        text-align: center;
        font-family: 'Epilogue', sans-serif;
        font-size: 32px;
    }
    .position-wrapper span {
        font-size: 48px;
    }
    .team-column {
        width: 100%;
        max-width: 240px;
        display: flex;
        gap: 32px;
        flex-direction: column;
        align-items: center;
        &.pos-1 {
            order: 2;
        }
        &.pos-2 {
            order: 1;
            max-height: 450px;
        }
        &.pos-3 {
            order: 3;
            max-height: 400px;
        }
        .score-wrapper {
            padding: 8px 40px 4px;
        }
    }

    .position-wrapper {
        border-radius: 24px 24px 0 0;
        width: 100%;
        //max-width: 240px;
        background: #ffffff;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        //box-shadow: -4px -2px 0px 2px;
        border: 1px solid rgb(51, 77, 86);
        &:before {
            content: '';
            position: absolute;
            bottom: -1px;
            left: -50%;
            width: 200vw;
            height: 1px;
            background: #334d56;
            transform: translateX(-50%);
        }
    }
}

.ranking-title {
    font-family: 'Epilogue', sans-serif;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    display: flex;
    gap: 8px;
    flex-direction: column;
}
.undertext {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    .highlight {
        font-weight: 800;
    }
}

.final-ranking {
    max-width: 800px;
    width: 800px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 16px;
    font-family: 'Epilogue', sans-serif;
    font-weight: 800;
    margin-top: 24px;
    .team-row {
        display: flex;
        align-items: center;
        gap: 24px;
        font-size: 20px;
        + .team-row {
            border-top: 1px solid;
        }
        &:first-child {
            .position-wrapper {
                border-radius: 16px 0 0 0;
            }
        }
        &:last-child {
            .position-wrapper {
                border-radius: 0 0 0 16px;
            }
        }
        .id-wrapper {
            text-align: center;
        }
        .position-wrapper {
            text-align: center;
            width: 100%;
            max-width: 80px;
            padding: 4px;
        }
        .name-wrapper {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 24px;
            text-align: left;
        }
        .end-score {
            margin-left: auto;
            margin-right: 24px;
        }
    }
}

.intro-video-page {
    height: 100%;
    text-align: center;
    .video-player {
        height: calc(100% - 112px);
        max-height: initial;
        video {
            width: unset;
            height: 100%;
            object-fit: contain;
        }
    }
}

.vuistregel-screen {
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1000px;
    align-items: flex-start;

    ol {
        padding-left: 32px;
    }

    .vuistregel-content {
        padding: 80px;
    }

    h1 {
        margin-bottom: 1rem;
    }

    .list-item {
        font-size: 1.9rem;
        line-height: 1.5;
        opacity: 0;
        transform: translateX(100%);
        animation: fadeInRight 0.5s forwards;
    }
}

@keyframes fadeInRight {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
